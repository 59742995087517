import React from 'react';
import ResponsiveTextAndImage from './TextImage';
import Spacer from './Spacer';
import Servicos from './Serviços';

const aboutUsStyles = {
    aboutUsContainer: {
        maxWidth: '1000px',
        margin: '0 auto',
        padding: '20px',
    },

    step: {
        marginBottom: '40px',
    },

    stepHeading: {
        color: '#007bff',
    },
    h1: {
        textAlign: 'center',
        fontSize: '40px',
        fontWeight: '450',
        color: '#0069c0',
        padding: '20px',
        //WebkitTextStroke
    }

};



const AboutUs = () => {

    const valores = [
        {
            imagemSrc: "https://images.pexels.com/photos/3153198/pexels-photo-3153198.jpeg?auto=compress&cs=tinysrgb&w=600",
            alt: "Missão",
            descricao: "Missão",
            subDescricao: "Nosso compromisso é oferecer soluções tecnológicas inovadoras e personalizadas para impulsionar o sucesso de nossos clientes. Buscamos constantemente superar expectativas, promovendo a excelência e estabelecendo parcerias duradouras baseadas na confiança e no profissionalismo.",
        },
        {
            imagemSrc: "https://images.pexels.com/photos/7709022/pexels-photo-7709022.jpeg?auto=compress&cs=tinysrgb&w=600",
            alt: "Visão",
            descricao: "Visão",
            subDescricao: "Ser reconhecida como líder no mercado de tecnologia, oferecendo soluções de vanguarda que inspirem e transformem negócios em todo o mundo. Buscamos ser referência em inovação, qualidade e excelência no atendimento ao cliente.",
        },
        {
            imagemSrc: "https://images.pexels.com/photos/3184339/pexels-photo-3184339.jpeg?auto=compress&cs=tinysrgb&w=600",
            alt: "Valores",
            descricao: "Valores",
            subDescricao: "Na CityIT, priorizamos a inovação, com foco no cliente, integridade e excelência em produtos e serviços. Valorizamos a colaboração e a responsabilidade social e ambiental, buscando contribuir positivamente para nossa comunidade e o meio ambiente. Acreditamos que esses valores fundamentais nos capacitam a construir uma empresa sólida e confiável, promovendo o sucesso conjunto de nossos clientes, equipe e sociedade.",
        },
    ];

    const firstBtn = {
        render:false,
        text: "Contate-nos",
        styles: {
            backgroundColor: '#0069c0',
            color: 'white',
            textAlign: 'center',
            borderRadius: '8px',
            padding: '16px',

        },
        link: "https://example.com"
    };
    return (
        <>
            <h1 style={aboutUsStyles.h1 }>Sobre Nós</h1>
            <Spacer />
            <ResponsiveTextAndImage title="Conheça a CityIT: Sua Nova Parceira em Soluções Tecnológicas"
                text="Somos uma empresa jovem e dinâmica, apaixonada por tecnologia e pela busca incessante de soluções inovadoras. Nosso objetivo é oferecer serviços que não apenas atendam, mas superem as expectativas de nossos clientes. Com uma equipe talentosa e comprometida, estamos prontos para enfrentar qualquer desafio que o mundo digital nos apresente. Estamos ansiosos para embarcar nesta jornada de descoberta tecnológica ao seu lado."
                imageUrl="https://images.pexels.com/photos/6153354/pexels-photo-6153354.jpeg?auto=compress&cs=tinysrgb&w=1260"
                imageOnRight={true}
                button={firstBtn }            />
            <Spacer />
            <Servicos text="Nosso Compromisso" servicos={valores} />

        </>
      
    );
};

export default AboutUs;