import React from 'react';
import ButtonCustom from './ButtonCustom';


const TextAndImageMobileOverlay = ({ title, text, imageUrl,button }) => {
    const containerStyle = { width: '100%', height: '60vh', position: 'relative', padding: '5%',textHeigth:'600' };
    const imageStyle = { width: '100%', height: '100%', objectFit: 'cover', padding: '5%' };
    const overlayStyle = {
        position: 'absolute',
        top: '5%',
        left: '5%',
        right: '5%',
        bottom: '5%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.65)', //#ab3ed8 roxo
        color: '#fff',
        textAlign: 'center'
    };
    const descriptionStyle = {
        fontSize: '20px',
    };


    return (
        <div style={containerStyle}>
            <img src={imageUrl} alt="Imagem" style={imageStyle} />
            <div style={overlayStyle}>
                <h2>{title}</h2>
                <h3 style={descriptionStyle}>{text} </h3>
                {button.render && (<ButtonCustom
                    text={button.text}
                    styles={button.styles}
                    link={button.link}
                />)}
            </div>
        </div>
    );
};

export default TextAndImageMobileOverlay;
