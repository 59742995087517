import React from 'react';

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center', // Center items vertically
        justifyContent: 'center', // Center items horizontally
        padding: '25px 10px',
        position: 'relative', // Set container position to relative
    },
    logoContainer: {
        position: 'absolute', // Set logoContainer position to absolute
        right: 'calc(50% + 25px)', // Position it 15px from the right edge of the separator
    },
    separator: {
        position: 'relative', // Set separator position to relative
        borderRight: '1px solid #0069c0',
        height: '20px',
        marginRight: '15px',
    },
    span: {
        position: 'absolute', // Set span position to absolute
        fontSize: "22px",
        left: 'calc(50% + 7px)',
        color: 'gray',
    },
    overlayText: {
        position: 'absolute',
        width: '150px',
        height: '44px',
        top: '5px', // Adjust as necessary
        left: '50%',
        transform: 'translateX(-50%) rotate(-10deg)',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        color: 'white',
        padding: '10px 10px',
        borderRadius: '5px',
        zIndex: 1,
    },
};

function LogoBrand({ logo, logoText, status }) {
    return (
        <div style={styles.container}>
            <div style={styles.overlayText}>{status}</div>
            <div style={styles.logoContainer}>
                {logo}
            </div>
            <div style={styles.separator}></div>
            <span style={styles.span}>{logoText}</span>
        </div>
    );
}

export default LogoBrand;
