import React, { useState, useEffect } from 'react';

const PalavraAleatoria = ({ palavras }) => {
    const [indicePalavra, setIndicePalavra] = useState(0);
    const [palavraAtual, setPalavraAtual] = useState('');
    const cityRoxo = 'rgb(171, 62, 216)';
    const cityAzul = 'rgb(0, 105, 192)';


    useEffect(() => {
        if (!palavras || palavras.length === 0) {
            return;
        }

        setPalavraAtual(palavras[indicePalavra]);

        const intervalId = setInterval(() => {
            setIndicePalavra((prevIndice) => (prevIndice + 1) % palavras.length);
        }, 5000);

        return () => clearInterval(intervalId);
    }, [indicePalavra, palavras]);

    const PrimeirasLetrasColoridas = ({ palavra }) => {
        const [indice, setIndice] = useState(0);
        const [textoDigitado, setTextoDigitado] = useState('');

        let iIndex = -1;
        let tIndex = -1;

        for (let i = 0; i < palavra.length; i++) {
            if (iIndex === -1 && palavra[i].toLowerCase() === 'i') {
                iIndex = i;
            }
            if (tIndex === -1 && palavra[i].toLowerCase() === 't') {
                tIndex = i;
            }
        }

        useEffect(() => {
            const intervalId = setInterval(() => {
                if (indice < palavra.length) {
                    setTextoDigitado((prevTexto) => prevTexto + palavra[indice]);
                    setIndice((prevIndice) => prevIndice + 1);
                }
            }, 200);

            return () => clearInterval(intervalId);
        }, [palavra, indice]);

        return (
            <div>
                {textoDigitado.split('').map((letra, index) => (
                    <span
                        key={index}
                        style={{
                            color: index === iIndex ? cityRoxo : index === tIndex ? cityAzul : 'white',
                        }}
                    >
                        {letra}
                    </span>
                ))}
            </div>
        );
    };

    return <PrimeirasLetrasColoridas palavra={palavraAtual} />;
};

export default PalavraAleatoria;