import React, { useState, useEffect } from 'react';
import { Button, Form, FloatingLabel } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import Spacer from './Spacer';
import Toasted, { notifySuccess } from './Toasted';
import axios from 'axios';

const Cadastro = () => {
    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [telefone, setTelefone] = useState('');
    const [cnpj, setCnpj] = useState('');
    const [assunto, setAssunto] = useState('');
    const [empresa, setEmpresa] = useState('');
    const [descricao, setDescricao] = useState('');
    const [telefoneMask, setTelefoneMask] = useState('(99) 9999-99999');
    const [validated, setValidated] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        const aplicarMascara = (valor) => {
            let value = valor.replace(/\D/g, '');

            if (value.length === 10) {
                setTelefoneMask('(99) 9999-99999');
                value = value.replace(/^(\d{2})(\d{4})(\d{4})$/, '($1) $2-$3');
            } else if (value.length === 11) {
                setTelefoneMask('(99) 99999-9999');
                value = value.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3');
            }

            return value;
        };

        setTelefone(aplicarMascara(telefone));
    }, [telefone]);

    const handleTelefoneChange = (e) => {
        setTelefone(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        setValidated(true);

        if (
            !nome ||
            !validateEmail(email) ||
            !validateTelefone(telefone) ||
            !validateCNPJ(cnpj) ||
            !empresa ||
            !assunto ||
            !descricao
        ) {
            e.stopPropagation();
            return;
        }

        setSubmitting(true);

        const formData = new FormData();
        formData.append('nome', nome);
        formData.append('email', email);
        formData.append('telefone', telefone);
        formData.append('cnpj', cnpj);
        formData.append('empresa', empresa);
        formData.append('assunto', assunto);
        formData.append('descricao', descricao);

        axios.post('/city/process_form.php', formData)
            .then(response => {
                if (response.status !== 200) {
                    throw new Error('Erro ao enviar o formulário. Código de resposta: ' + response.status);
                }
                notifySuccess('Formulário enviado com sucesso!');
                resetForm();
            })
            .catch(error => {
                console.error('Erro:', error);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const resetForm = () => {
        setNome('');
        setEmail('');
        setTelefone('');
        setCnpj('');
        setEmpresa('');
        setAssunto('');
        setDescricao('');
        setValidated(false);
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validateTelefone = (telefone) => {
        const telefoneRegex = /^\(\d{2}\) \d{4,5}-\d{4}$/;
        return telefoneRegex.test(telefone);
    };

    const validateCNPJ = (cnpj) => {
        cnpj = cnpj.replace(/[^\d]+/g, '');

        if (cnpj.length !== 14) return false;

        if (/^(\d)\1{13}$/.test(cnpj)) return false;

        let tamanho = cnpj.length - 2;
        let numeros = cnpj.substring(0, tamanho);
        let digitos = cnpj.substring(tamanho);
        let soma = 0;
        let pos = tamanho - 7;

        for (let i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2) pos = 9;
        }

        let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado !== parseInt(digitos.charAt(0))) return false;

        tamanho = tamanho + 1;
        numeros = cnpj.substring(0, tamanho);
        soma = 0;
        pos = tamanho - 7;

        for (let i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2) pos = 9;
        }

        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado !== parseInt(digitos.charAt(1))) return false;

        return true;
    };

    const styles = {
        descricao: {
            textAlign: 'center',
            fontSize: '40px',
            fontWeight: '450',
            color: '#0069c0',
            padding: '20px',
        },
        subDescricao: {
            color: '#ab3ed8',
            padding: '10px 0px 20px 0px',
            fontSize: '20px',
        },
        textArea: {
            overflow: 'hidden',
            resize: 'none',
            fontWeight: '450',
        },
        button: {
            backgroundColor: '#0069c0',
            color: 'white',
            border: 'none',
            transition: 'background-color 0.3s ease',
            width: '25%',
            marginBottom: '1rem',
        },
        buttonHover: {
            backgroundColor: '#ab3ed8',
        },
    };

    const [hover, setHover] = useState(false);

    return (
        <>
            <h1 className="text-center" style={styles.descricao}>Junte-se à Revolução Tecnológica!</h1>
            <Spacer />
            <div className="container" style={{ maxWidth: '1000px', paddingTop: '20px' }}>
                <h2 style={styles.subDescricao}>
                    Solicite uma cotação e descubra como nossas tecnologias podem transformar seus projetos.<br/>Envie sua mensagem sobre financeiro, comercial, suporte técnico ou qualquer dúvida.<br/>Estamos aqui para ajudar!


                </h2>
                <Spacer />
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <FloatingLabel controlId="nome" label="Nome" className="mb-3 mt-3">
                        <Form.Control
                            type="text"
                            value={nome}
                            onChange={(e) => setNome(e.target.value)}
                            required
                            placeholder="Nome"
                            className="custom-placeholder"
                            isInvalid={validated && !nome}
                        />
                        <Form.Control.Feedback type="invalid">
                            Por favor, preencha seu nome.
                        </Form.Control.Feedback>
                    </FloatingLabel>
                    <FloatingLabel controlId="email" label="Email" className="mb-3">
                        <Form.Control
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            placeholder="Email"
                            className="custom-placeholder"
                            isInvalid={validated && !validateEmail(email)}
                        />
                        <Form.Control.Feedback type="invalid">
                            Por favor, insira um email válido.
                        </Form.Control.Feedback>
                    </FloatingLabel>
                    <FloatingLabel controlId="telefone" label="Telefone" className="mb-3">
                        <InputMask
                            mask={telefoneMask}
                            value={telefone}
                            onChange={handleTelefoneChange}
                            maskChar=""
                        >
                            {() => (
                                <Form.Control
                                    type="text"
                                    required
                                    placeholder="Telefone"
                                    className="custom-placeholder"
                                    isInvalid={validated && !validateTelefone(telefone)}
                                />
                            )}
                        </InputMask>
                        <Form.Control.Feedback type="invalid">
                            Por favor, insira um telefone válido.
                        </Form.Control.Feedback>
                    </FloatingLabel>
                    <FloatingLabel controlId="cnpj" label="CNPJ" className="mb-3">
                        <InputMask
                            mask="99.999.999/9999-99"
                            value={cnpj}
                            onChange={(e) => setCnpj(e.target.value)}
                            maskChar=""
                        >
                            {() => (
                                <Form.Control
                                    type="text"
                                    required
                                    placeholder="CNPJ"
                                    className="custom-placeholder"
                                    isInvalid={validated && !validateCNPJ(cnpj)}
                                />
                            )}
                        </InputMask>
                        <Form.Control.Feedback type="invalid">
                            Por favor, insira um CNPJ válido.
                        </Form.Control.Feedback>
                    </FloatingLabel>
                    <FloatingLabel controlId="empresa" label="Empresa" className="mb-3">
                        <Form.Control
                            type="text"
                            value={empresa}
                            onChange={(e) => setEmpresa(e.target.value)}
                            required
                            placeholder="Empresa"
                            className="custom-placeholder"
                            isInvalid={validated && !empresa}
                        />
                        <Form.Control.Feedback type="invalid">
                            Por favor, preencha o nome da empresa.
                        </Form.Control.Feedback>
                    </FloatingLabel>
                    <FloatingLabel controlId="assunto" label="Assunto" className="mb-3">
                        <Form.Select
                            value={assunto}
                            onChange={(e) => setAssunto(e.target.value)}
                            required
                            className="custom-placeholder"
                            isInvalid={validated && !assunto}
                        >
                            <option value="">Selecione um assunto</option>
                            <option value="Cotacao">Cotação</option>
                            <option value="Comercial">Comercial</option>
                            <option value="Financeiro">Financeiro</option>
                            <option value="Suporte">Suporte Técnico</option>
                            <option value="Duvidas">Dúvidas/Sugestões</option>
                            <option value="Outro">Outro</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                            Por favor, selecione um assunto.
                        </Form.Control.Feedback>
                    </FloatingLabel>
                    <FloatingLabel controlId="descricao" label="Descrição" className="mb-3">
                        <Form.Control
                            as="textarea"
                            value={descricao}
                            onChange={(e) => setDescricao(e.target.value)}
                            onInput={(e) => {
                                e.target.style.height = 'auto';
                                e.target.style.height = `${e.target.scrollHeight}px`;
                            }}
                            style={styles.textArea}
                            required
                            placeholder="Descrição"
                            className="custom-placeholder"
                            isInvalid={validated && !descricao}
                        />
                        <Form.Control.Feedback type="invalid">
                            Por favor, insira uma descrição.
                        </Form.Control.Feedback>
                    </FloatingLabel>
                    <Button
                        variant="primary"
                        type="submit"
                        style={hover ? { ...styles.button, ...styles.buttonHover } : styles.button}
                        onMouseEnter={() => setHover(true)}
                        onMouseLeave={() => setHover(false)}
                        disabled={submitting}
                    >
                        {submitting ? 'Enviando...' : 'Enviar'}
                    </Button>
                </Form>
                <Toasted />
            </div>
        </>
    );
};

export default Cadastro;
