import React from 'react';

const Spacer = () => {
    const spacerStyle = {
        height: '3px',
        width: '25%',
        backgroundColor: '#0069c0',
        margin: '0 auto',
      
    };

    return <div style={spacerStyle}></div>;
};

export default Spacer;
