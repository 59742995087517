import React from 'react';

const Copyright = () => {
    return (
        <footer style={{ textAlign: 'center', padding: '0 0 15px 0 ', backgroundColor: '#0069c0', color: '#97e9ff' }}>
            &copy; {new Date().getFullYear()} CityIT. Todos os direitos reservados.
        </footer>
    );
}

export default Copyright;
