import React, { useState } from 'react';
import {Link} from 'react-router-dom';

const ButtonCustom = ({ text, styles, link }) => {
    const [hovered, setHovered] = useState(false);

    const buttonStyle = {
        ...styles,
        textDecoration: 'none',
        transition: 'background-color 0.3s ease', // Adding transition for smoother color change
        backgroundColor: hovered ? '#ab3ed8' : (styles.backgroundColor || 'transparent'),
    };


    const linkStyle = {
        textDecoration: 'none',


    }

    const margin = {
        marginTop: '10%',
    };

    return (
        <>
            <Link to={`/${link}`} style={linkStyle}>
            <div style={margin}>
                <a
                  
                    style={buttonStyle}
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                >
                    {text}
                </a>
            </div>
            </Link>
        </>
    );
};

export default ButtonCustom;
