import React from 'react';
import LogoBrand from './LogoBrand';
import { GrApple } from "react-icons/gr";
import { BsMicrosoft } from "react-icons/bs";
import { SiFortinet } from "react-icons/si";
import { SiZebratechnologies } from "react-icons/si";
import { SiIbm } from "react-icons/si";
import { GrAruba } from "react-icons/gr";
import { SiLenovo } from "react-icons/si";
import { SiDell } from "react-icons/si";
import { FaAws } from "react-icons/fa";
import { SiTrendmicro } from "react-icons/si";
import { SiIntel } from "react-icons/si";
import { SiEpson } from "react-icons/si";
import { SiAmd } from "react-icons/si";
import { SiCisco } from "react-icons/si";
import { SiNvidia } from "react-icons/si";
import Spacer from './Spacer';







const styles = {
    container: {
        width: '90%',
        margin: '0 auto',
        padding: '3% 0'
    },
    grid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(400px, 1fr))', // Three columns by default
        justifyContent: 'center',
        alignItems: 'center',
    },
    descricao: {
        textAlign: 'center',
        fontSize: '40px',
        fontWeight: '450',
        color: '#0069c0',
        padding: '20px',
        //WebkitTextStroke

    },
};

const size = 48


const Parceiros = () => {
    return (
        <>
            <h1 className="text-center" style={styles.descricao}>Parcerias em desenvolvimento</h1>
            <Spacer/>
            <div style={styles.container}>
                <div style={styles.grid}>
                    <LogoBrand status={"wip"} logo={<GrApple size={size} color={'gray'} />} logoText="Apple" />
                    <LogoBrand status={"wip"} logo={<BsMicrosoft size={size} color={'gray'} />} logoText="Microsoft" />
                    <LogoBrand status={"wip"} logo={<SiFortinet size={size} color={'gray'} />} logoText="Fortinet" />
                    <LogoBrand status={"wip"} logo={<SiCisco size={size} color={'gray'} />} logoText="Cisco" />
                    <LogoBrand status={"wip"} logo={<SiZebratechnologies size={size} color={'gray'} />} logoText="Zebra" />
                    <LogoBrand status={"wip"} logo={<SiIbm size={size} color={'gray'} />} logoText="IBM" />
                    <LogoBrand status={"wip"} logo={<GrAruba size={size} color={'gray'} />} logoText="Aruba" />
                    <LogoBrand status={"wip"} logo={<SiLenovo size={size} color={'gray'} />} logoText="Lenovo" />
                    <LogoBrand status={"wip"} logo={<SiDell size={size} color={'gray'} />} logoText="Dell" />
                    <LogoBrand status={"wip"} logo={<FaAws size={size} color={'gray'} />} logoText="Amazon" />
                    <LogoBrand status={"wip"} logo={<SiTrendmicro size={size} color={'gray'} />} logoText="TrendMicro" />
                    <LogoBrand status={"wip"} logo={<SiIntel size={size} color={'gray'} />} logoText="Intel" />
                    <LogoBrand status={"wip"} logo={<SiEpson size={size} color={'gray'} />} logoText="Epson" />
                    <LogoBrand status={"wip"} logo={<SiAmd size={size} color={'gray'} />} logoText="AMD" />
                    <LogoBrand status={"wip"} logo={<SiNvidia size={size} color={'gray'} />} logoText="NVidia" />


                </div>
            </div>
        </>
    );
};

export default Parceiros;
