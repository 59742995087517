import React from 'react';
import { ReactComponent as LogoSVG } from '../assets/logo_name_bw.svg'; // Importe seu logotipo SVG

const Logo = ({ color1, color2, strokeWidth, bg }) => {

    const logoSVGStyle = {
        padding: '0px 0px 10px 0px',
        width: '120px',
        alignSelf: 'center', // Alinha o SVG ao centro verticalmente
        fill: color1,
        stroke: color2,
        strokeWidth: strokeWidth,
        backgroundColor: bg,
    };

    const divLogo = {
        backgroundColor: bg,
        display: 'flex',
        justifyContent: 'center'
    }

    return (
        <>
            <div style={divLogo}>
                <LogoSVG style={logoSVGStyle} />
            </div>
        </>
    );
};

export default Logo;



