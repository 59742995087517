import React from 'react';
import ButtonCustom from './ButtonCustom';
import { Link } from 'react-router-dom';


const TextAndImage = ({ title, text, imageUrl, imageOnRight,button }) => {
    const isImageOnRight = !!imageOnRight;

    const containerStyle = { width: '100%', height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center' };
    const imageStyle = { width: '100%', height: '100%', objectFit: 'cover' };
    const textSideStyle = { flex: 2, textAlign: isImageOnRight ? 'right' : 'left', zIndex: 2 };
    const imageSideStyle = { flex: 3, textAlign: isImageOnRight ? 'right' : 'left', zIndex: 1 };
    const gridStyle = {
        padding: "7% 5%",
        display: 'grid',
        gridTemplateColumns: isImageOnRight ? '2fr 3fr' : '3fr 2fr',
        gap: '50px',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%'
    };

    const mediaQuery = `@media (max-width: 800px) {
        .grid-container {
            grid-template-columns: 1fr !important;
        }
        .text-side, .image-side {
            flex: none !important;
            width: 100% !important;
            text-align: center !important;
            position: relative !important;
            z-index: 2 !important;
        }
        .image-side img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: 1;
        }
    }`;

    const styles = {
        titulo: {
            color: '#0069c0',
            fontSize: '32px', // Adicione o tamanho da fonte desejado
        },
        descricao: {
            color: '#ab3ed8',
            fontSize: '20px', // Adicione o tamanho da fonte desejado
        },
    };


    const right = (
        <>
            <div className="text-side" style={textSideStyle}>
                <h2 style={styles.titulo}>{title}</h2>
                <h3 style={styles.descricao}>{text}</h3>
                {button.render && (<ButtonCustom
                    text={button.text}
                    styles={button.styles}
                    link={button.link}
                />)}
            </div>
            <div className="image-side" style={imageSideStyle}>
                <div style={containerStyle}>
                    <img src={imageUrl} alt="Imagem" style={imageStyle} />
                </div>

            </div>
        </>
    );

    const left = (
        <>
            <div className="image-side" style={imageSideStyle}>
                <div style={containerStyle}>
                    <img src={imageUrl} alt="Imagem" style={imageStyle} />
                </div>
            </div>
            <div className="text-side" style={textSideStyle}>
                <h2 style={styles.titulo}>{title}</h2>
                <h3 style={styles.descricao}>{text}</h3>
                {button.render && (<ButtonCustom
                    text={button.text}
                    styles={button.styles}
                    link={button.link}
                />)}
            </div>

        </>
    );

    return (
        <>
            <style>{mediaQuery}</style>
            <div className="grid-container" style={gridStyle}>
                {window.innerWidth <= 800 ? (
                    <div className="image-behind" style={{ position: 'relative' }}>
                        <img src={imageUrl} alt="Imagem" style={{ ...imageStyle, zIndex: -1 }} />
                        <div className="text-side" style={{ ...textSideStyle, position: 'absolute', top: 0, left: 0 }}>
                            <h2>{title}</h2>
                            <p>{text}</p>
                        </div>
                    </div>
                ) : (
                    isImageOnRight ? right : left
                )}
            </div>
        </>
    );
};

export default TextAndImage;
