import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';


const NavItem = ({ to, label, isActive, onClick }) => (
    <li style={{ marginRight: '30px', listStyleType: 'none' }}>
        <Link
            to={to}
            onClick={onClick}
            style={{
                color: isActive ? '#ab3ed8' : '#0069c0',
                textDecoration: 'none',
                fontSize: '16px',
                fontWeight: '600',
                fontFamily: 'Source Sans Variable, sans-serif',
            }}
        >
            {label}
        </Link>
    </li>
);

const NavbarLarge = ({ activeNavItem, handleNavItemClick }) => (
    <nav style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',  // Centraliza verticalmente
        padding: '10px',
        backgroundColor: '#ffffff',
        height: '10vh',  // Altura do navbar
    }}>
        <ul style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',  // Preenchimento vertical
            alignItems: 'center',  // Centraliza horizontalmente
            height: '100%',  // Ocupa toda altura do nav
            margin: 0,  // Remove margens padrão
            padding: 0,  // Remove padding padrão
        }}>
            <NavItem
                to="/"
                label="Início"
                isActive={activeNavItem === 'home'}
                onClick={() => handleNavItemClick('home')}
            />
            <NavItem
                to="/sobre"
                label="Sobre"
                isActive={activeNavItem === 'about'}
                onClick={() => handleNavItemClick('about')}
            />
            <NavItem
                to="/parceiros"
                label="Parceiros"
                isActive={activeNavItem === 'partner'}
                onClick={() => handleNavItemClick('partner')}
            />
            <NavItem
                to="/contato"
                label="Contato"
                isActive={activeNavItem === 'contact'}
                onClick={() => handleNavItemClick('contact')}
            />
        </ul>
    </nav>
);

const HamburgerButton = ({ isMenuOpen, toggleMenu }) => (
    <button onClick={toggleMenu} style={{ fontSize: '24px' }}>
        {isMenuOpen ? '✕' : '☰'}
    </button>
);

const NavbarSmall = ({ activeNavItem, handleNavItemClick }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleNavItemClickAndClose = (item) => {
        handleNavItemClick(item);
        setIsMenuOpen(false);
    };

    return (
        <nav style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '10px',
            position: 'relative',
            zIndex: 10,
        }}>
            <HamburgerButton isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
            {isMenuOpen && (
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: '10px 0',
                    position: 'fixed',
                    top: '10vh',
                    left: 0,
                    right: 0,
                    zIndex: 1,
                    backgroundColor: '#ffffff',
                    transform: `translateX(${isMenuOpen ? '0%' : '-100%'})`,
                    transition: 'transform 0.3s ease-in-out',
                }}>
                    <NavItem to="/" label="Início" isActive={activeNavItem === 'home'} onClick={() => handleNavItemClickAndClose('home')} />
                    <div style={{
                        width: '100%',
                        height: '1px',
                        backgroundColor: '#ced4da',
                        margin: '8px 0',
                    }}></div>
                    <NavItem to="/sobre" label="Sobre" isActive={activeNavItem === 'about'} onClick={() => handleNavItemClickAndClose('about')} />
                    <div style={{
                        width: '100%',
                        height: '1px',
                        backgroundColor: '#ced4da',
                        margin: '8px 0',
                    }}></div>
                    <NavItem to="/parceiros" label="Parceiros" isActive={activeNavItem === 'partner'} onClick={() => handleNavItemClickAndClose('partner')} />
                    <div style={{
                        width: '100%',
                        height: '1px',
                        backgroundColor: '#ced4da',
                        margin: '8px 0',
                    }}></div>
                    <NavItem to="/contato" label="Contato" isActive={activeNavItem === 'contact'} onClick={() => handleNavItemClickAndClose('contact')} />
                </div>
            )}
        </nav>
    );
};

const ResponsiveNavbar = () => {
    const location = useLocation();
    const [activeNavItem, setActiveNavItem] = useState('home');
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 800);

    useEffect(() => {
        const checkScreenSize = () => {
            setIsSmallScreen(window.innerWidth < 800);
        };

        window.addEventListener('resize', checkScreenSize);

        return () => window.removeEventListener('resize', checkScreenSize);
    }, []);

    useEffect(() => {
        // Update activeNavItem based on the current path
        switch (location.pathname) {
            case '/':
                setActiveNavItem('home');
                break;
            case '/sobre':
                setActiveNavItem('about');
                break;
            case '/parceiros':
                setActiveNavItem('partner');
                break;
            case '/contato':
                setActiveNavItem('contact');
                break;
            default:
                setActiveNavItem('home');
        }
    }, [location.pathname]);

    const handleNavItemClick = (item) => {
        setActiveNavItem(item);
    };

    return isSmallScreen
        ? <NavbarSmall activeNavItem={activeNavItem} handleNavItemClick={handleNavItemClick} />
        : <NavbarLarge activeNavItem={activeNavItem} handleNavItemClick={handleNavItemClick} />;
};

export default ResponsiveNavbar;
