import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faXTwitter, faWhatsapp, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { FaEnvelope } from 'react-icons/fa';
import Logo from './Logo';
import Copyright from './Copyright';

const Footer = () => {

    const backColor = '#0069c0';
    const mainDiv = {
        backgroundColor: backColor,
    };

    const footerStyle = {
        display: 'flex',
        justifyContent: 'space-around',
        color: '#97e9ff',
        padding: '20px',
    };

    const columnStyle = {
        flex: 1,
        textAlign: 'center',
        marginBottom: '20px',
        padding: '0 2%', // Add margin at the bottom for better spacing
    };

    const headingStyle = {
        color: 'white', // React's official color
    };

    const socialMediaStyle = {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '10px',
    };

    const iconStyle = {
        margin: '0 10px',
        fontSize: '24px',
        color: '#97e9ff',
        cursor: 'pointer',
    };

    const emailStyle = {
        color: 'inherit',
        textDecoration: 'none',
    };

    return (
        <>
            <div style={mainDiv}>
                <footer style={footerStyle}>
                    <div style={columnStyle}>
                        <Logo color1="white" color2="white" strokeWidth={6} bg={backColor} />
                        <p>
                            A CityIT oferece soluções e revenda de tecnologia para empresas, otimizando processos e impulsionando a inovação.
                        </p>
                    </div>
                    <div style={columnStyle}>
                        <h2 style={headingStyle}>Contato</h2>
                        <p>
                            Granja Vianna - São Paulo<br />
                            Tel: <a href="https://wa.me/+5511993185963" style={emailStyle} target="_blank" rel="noopener noreferrer">(+55) 11 99318-5963</a><br />
                            Email: <a href="mailto:comercial@cityit.com.br" style={emailStyle}>comercial@cityit.com.br</a>
                        </p>
                    </div>
                    <div style={columnStyle}>
                        <h2 style={headingStyle}>Mídias</h2>
                        <p>
                            Mantenha-se conectado com a CityIT nas redes sociais para as últimas atualizações e notícias.
                        </p>
                        <div style={socialMediaStyle}>
                            <a href="https://wa.me/+5511993185963" target="_blank" rel="noopener noreferrer" aria-label="WhatsApp">
                                <FontAwesomeIcon icon={faWhatsapp} style={iconStyle} />
                            </a>
                            <a href="https://x.com/cityit_" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
                                <FontAwesomeIcon icon={faXTwitter} style={iconStyle} />
                            </a>
                            <a href="https://instagram.com/cityit_/" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
                                <FontAwesomeIcon icon={faInstagram} style={iconStyle} />
                            </a>
                            <a href="https://linkedin.com/company/cityitsolutions/" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
                                <FontAwesomeIcon icon={faLinkedinIn} style={iconStyle} />
                            </a>
                            <a href="mailto:comercial@cityit.com.br" aria-label="Email">
                                <FaEnvelope style={iconStyle} />
                            </a>
                        </div>
                    </div>
                </footer>
                <Copyright />
            </div>
        </>
    );
};

export default Footer;
