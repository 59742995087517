import React from 'react';

// Estilos CSS para o bot�o
const buttonStyle = {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    cursor: 'pointer',
};

const imageStyle = {
    width: '50px', // Ajuste a largura conforme necess�rio
    height: 'auto',
    animation: 'zoomInOut 1.2s infinite',
};


// Definindo a anima��o usando @keyframes
const styles = `
@keyframes zoomInOut {
      0% {
        transform: scale(1);
    }
    10% {
        transform: scale(1.2);
    }
    20% {
        transform: scale(1);
    }
    30% {
        transform: scale(1.2);
    }
    40% {
        transform: scale(1);
    }
    100% {
        transform: scale(1);
    }
}
`;

export function WhatsAppChatButton({number }) {

    // Fun��o para abrir o chat do WhatsApp
    const openWhatsAppChat = () => {
        // Substitua 'seu-numero-whatsapp' pelo n�mero do WhatsApp para o qual deseja enviar a mensagem
        window.open(`https://wa.me/${number}`, '_blank');
    };

    return (
        <div style={buttonStyle} onClick={openWhatsAppChat}>
            <style>{styles}</style>
            {/* �cone do WhatsApp */}
            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/WhatsApp.svg/598px-WhatsApp.svg.png" style={imageStyle} alt="WhatsApp" />
        </div>
    );
}
