import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  text-align: center;
`;

const Title = styled.h2`
  margin-top: 0;
`;

const Paragraph = styled.p`
  margin: 20px 0;
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const CookieModal = () => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const cookiesAccepted = localStorage.getItem('cookiesAccepted');
        if (!cookiesAccepted) {
            setIsOpen(true);
        }
    }, []);

    const handleAccept = () => {
        localStorage.setItem('cookiesAccepted', 'true');
        setIsOpen(false);
    };

    return (
        <>
            {isOpen && (
                <ModalOverlay>
                    <ModalContent>
                        <Title>Usamos Cookies</Title>
                        <Paragraph>
                            Este site usa cookies para melhorar sua experiência. Ao continuar navegando, você aceita o uso de cookies.
                        </Paragraph>
                        <Button onClick={handleAccept}>Aceitar</Button>
                    </ModalContent>
                </ModalOverlay>
            )}
        </>
    );
};

export default CookieModal;
