import React from 'react';

// Defina a constante de estilos
const styles = {

    servicosContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
    },
    servicoColuna: {
        textAlign: 'center',
        margin: '5px', // Reduzir a margem para aproximar as imagens das bordas
    },
    imagemServico: {
        objectFit: 'cover',
        position: 'relative',
        width: '350px',
        aspectRatio: '16 / 9', // Proporção de aspecto 16:9
    },
    descricaoContainer: {
        display: 'flex',
        flexDirection: 'column',

    },
    titulo: {
        textAlign: 'center',
        color: '#0069c0',
        padding: '45px',
    },
    descricao: {
        color: '#ab3ed8',
        fontSize: '20px',
        padding: '10px 0px 0px 5px',
        textAlign: 'left',
    },
    subDescricao: {
        color: '#0069c0',
        fontSize: '16px',
        maxWidth: '350px',
        textAlign: 'left',
        padding: '10px 0px 20px 5px',
        fontWeight: 'normal' // Adicione a propriedade fontWeight e defina o valor desejado
   
    },
};

const Servicos = ({...params }) => {
   const { servicos,text }  = params

    return (
        <>
            <h1 style={styles.titulo}>{text}</h1>
            <div style={styles.servicosContainer}>
                {servicos.map((servico, index) => (
                    <div key={index} style={styles.servicoColuna}>
                        <div style={styles.descricaoContainer}>
                            <img src={servico.imagemSrc} alt={servico.alt} style={styles.imagemServico} />
                            <h2 style={styles.descricao}>{servico.descricao}</h2>
                            <h3 style={styles.subDescricao}>{servico.subDescricao}</h3>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default Servicos;
