import React from 'react';
import TextAndImage from './Case';
import TextAndImageMobileOverlay from './TextImageOverlay';


const ResponsiveTextAndImage = ({ title, text, imageUrl, imageOnRight,button }) => {
    const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

    React.useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            {windowWidth <= 800 ? (
                <TextAndImageMobileOverlay title={title} text={text} imageUrl={imageUrl} button={button } />
            ) : (
                    <TextAndImage title={title} text={text} imageUrl={imageUrl} imageOnRight={imageOnRight} button={button } />
            )}
        </>
    );
};

export default ResponsiveTextAndImage;
