import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './App.css';
import Footer from './Components/Footer';
import Home from './Components/Home';
import Navbar from './Components/NavBar';
import Contato from './Components/Contato';
import About from './Components/AboutUs';
import Partner from './Components/Parceiros';
import { WhatsAppChatButton } from './Components/WhatsAppChatButton';
import CookieModal from './Components/Cookie';

const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
};

const Breadcrumbs = () => {
    const location = useLocation();
    const pathnameParts = location.pathname.split('/').filter(Boolean);

    return (
        <nav className="breadcrumbs">
            <Link to="/">Home</Link>
            {pathnameParts.map((part, index) => {
                const path = `/${pathnameParts.slice(0, index + 1).join('/')}`;
                const breadcrumbNameMap = {
                    sobre: 'Sobre',
                    parceiros: 'Parceiros',
                    contato: 'Contato',
                };
                return (
                    <span key={path}>
                        {' / '}
                        <Link to={path}>{breadcrumbNameMap[part] || part}</Link>
                    </span>
                );
            })}
        </nav>
    );
};

const AppContent = () => {
    const location = useLocation();

    let pageTitle = 'CityIT - Soluções em Tecnologia';
    let metaDescription = 'A CityIT oferece soluções e revenda de tecnologia para empresas, otimizando processos e impulsionando a inovação.';
    let canonicalUrl = 'https://cityit.com.br/';
    let structuredData = {};

    switch (location.pathname) {
        case '/':
            pageTitle = 'CityIT - Soluções em Tecnologia';
            canonicalUrl = 'https://cityit.com.br/';
            structuredData = {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "url": canonicalUrl,
                "name": "Home",
                "description": "Bem-vindo à CityIT - Soluções em Tecnologia."
            };
            break;
        case '/sobre':
            pageTitle = 'Sobre Nós - CityIT';
            metaDescription = 'Saiba mais sobre a CityIT, nossa missão, visão e valores. Descubra como oferecemos soluções tecnológicas para empresas e impulsionamos a inovação.';
            canonicalUrl = 'https://cityit.com.br/sobre';
            structuredData = {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "url": canonicalUrl,
                "name": "Sobre",
                "description": metaDescription
            };
            break;
        case '/parceiros':
            pageTitle = 'Parceiros - CityIT';
            metaDescription = 'Conheça os parceiros da CityIT. Trabalhamos com empresas líderes para oferecer as melhores soluções tecnológicas e serviços de alta qualidade.';
            canonicalUrl = 'https://cityit.com.br/parceiros';
            structuredData = {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "url": canonicalUrl,
                "name": "Parceiros",
                "description": metaDescription
            };
            break;
        case '/contato':
            pageTitle = 'Contato - CityIT';
            metaDescription = 'Entre em contato com a CityIT. Estamos à disposição para responder suas dúvidas e fornecer mais informações sobre nossas soluções tecnológicas.';
            canonicalUrl = 'https://cityit.com.br/contato';
            structuredData = {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "url": canonicalUrl,
                "name": "Contato",
                "description": metaDescription
            };
            break;
        default:
            break;
    }

    return (
        <div className="app">
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={metaDescription} />
                <link rel="canonical" href={canonicalUrl} />
                <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
            </Helmet>
            <CookieModal />
            <ScrollToTop />
            <header className="app-header">
                <div className="logo">
                    <img src="/img/logo_name_city.svg" alt="Logo" className="flex-item" />
                </div>
                <Navbar />
            </header>
            <Breadcrumbs />
            <main className="app-main">
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/sobre" element={<About />} />
                    <Route path="/parceiros" element={<Partner />} />
                    <Route path="/contato" element={<Contato />} />
                </Routes>
            </main>
            <Footer />
            <WhatsAppChatButton number='+5511993185963' />
        </div>
    );
};

const App = () => {
    return (
        <Router>
            <AppContent />
        </Router>
    );
};

export default App;
