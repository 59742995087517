import React, { useEffect, useState } from 'react';
import Palavra from './Palavra';
import Servicos from './Serviços';
import Spacer from './Spacer';
import ResponsiveTextAndImage from './TextImage';





const homeStyles = {
    homeContainer: {
        textAlign: 'left',
        margin: '0',
        height: '70vh',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
        marginBottom: '5%'
    },
    overlay: {
        height: '70vh',
        width: '100%',
        position: 'absolute',
        backgroundColor: 'rgba(0, 0, 0,0.5)',
        top: '0',
        left: '0',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',  // Align text to the left
        justifyContent: 'center',
        padding: '20px',  // Add padding for better spacing
        fontFamily: 'Roboto, sans-serif', // Updated font family
    },
    companyLogo: {
        width: '400px',
        height: 'auto',
        marginBottom: '20px',
    },
    heading: {
        fontSize: '64px',
        fontWeight: '600',
        color: '#ffffff',
        margin: '0',
        maxWidth: '600px',
        padding: '20px',
    },
    headingSmall: {
        fontSize: '42px',
    },

    subheading: {
        fontWeight: '600',
        color: '#ffffff',
        margin: '0',  // Remove default margin
    },
    videoBackground: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        objectFit: 'cover',  // Ensure the video covers the entire container
        zIndex: '-1',  // Ensure video is behind overlay content
    },

};

const servicos = [
    {
        imagemSrc: "https://images.pexels.com/photos/256381/pexels-photo-256381.jpeg?auto=compress&cs=tinysrgb&w=600",
        alt: "Tecnologia",
        descricao: "Tecnologia",
        subDescricao: "Abra as portas para um futuro digital inovador com nossas soluções tecnológicas personalizadas, impulsionando sua empresa para novos patamares de eficiência e competitividade.",
    },
    {
        imagemSrc: "https://images.pexels.com/photos/577585/pexels-photo-577585.jpeg?auto=compress&cs=tinysrgb&w=600",
        alt: "Segurança",
        descricao: "Segurança",
        subDescricao: "Proteja o coração de seus dados com nossa expertise em segurança cibernética, garantindo a tranquilidade de sua empresa contra ameaças digitais.",
    },
    {
        imagemSrc: "https://images.pexels.com/photos/325229/pexels-photo-325229.jpeg?auto=compress&cs=tinysrgb&w=600",
        alt: "DataCenter",
        descricao: "DataCenter",
        subDescricao: "Mantenha seu negócio pulsando sem interrupções com nossas soluções de DataCenter, oferecendo armazenamento confiável e acesso instantâneo às informações vitais da sua empresa.",
    },
];




const palavrasTecnologicas = [
    'Digitalização',
    'Inovação',
    'Computação',
    'Internet',
    'Segurança',
    'Tecnologia',
    'Software',
    'Hardware',
    'Multitarefa',
    'Inteligência',
    'Integrado',
    'Interativo',
    'Virtual',
    'Infotecnologia',
    'Cibersegurança',
    'Criptografia',
    'Sistemas',
    'Integração',
    'IoT', // Internet das Coisas
    'Robótica',
    'Transmissão',
    'Infraestrutura'
];

const firstBtn = {
    render: true,
    text: "Saiba mais",
    styles: {
        backgroundColor: '#0069c0',
        color: 'white',
        textAlign: 'center',
        borderRadius: '8px',
        padding: '16px',

    },
    link: "sobre"
};

const secondBtn = {
    render: true,
    text: "Contate-nos",
    styles: {
        backgroundColor: '#0069c0',
        color: 'white',
        textAlign: 'center',
        borderRadius: '8px',
        padding: '16px',

    },
    link: "contato"
};

const Home = () => {

    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 800);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 800);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <>
            <div style={homeStyles.homeContainer}>
                <video autoPlay muted loop style={homeStyles.videoBackground}>
                    <source src="https://storage.cdn-luma.com/lit_lite_inference_text2vid_v1.0/b4f23c73-bf71-4683-8884-8ffa11b4ca5f/watermarked_video0a4798f8113fb42fa8bd5cf7f27280f93.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <div style={homeStyles.overlay}>

                    <h1 style={{ ...homeStyles.heading, ...(isSmallScreen ? homeStyles.headingSmall : {}) }}>
                        CityIT é <br /> <Palavra palavras={palavrasTecnologicas} />
                    </h1>


                </div>

            </div>
            <Spacer />

            <Servicos text="Nossos Serviços" servicos={servicos} />
            <Spacer />
            <ResponsiveTextAndImage title="Soluções Tecnológicas: Confiabilidade em Cada Passo"
                text="Nossa empresa é sua parceira de confiança em soluções tecnológicas. Com um compromisso inabalável com a qualidade e a inovação, oferecemos produtos e serviços que inspiram tranquilidade em um mundo digital em constante evolução. Confie em nós para impulsionar seu sucesso com segurança e confiança."
                imageUrl="https://images.pexels.com/photos/2047905/pexels-photo-2047905.jpeg?auto=compress&cs=tinysrgb&w=1260"
                imageOnRight={true}
                button={firstBtn} />
            <Spacer />
            <ResponsiveTextAndImage title="Eficiência e Confiança: Nossa Marca Registrada"
                text="Nosso compromisso com a eficiência e a confiança é incomparável. Oferecemos soluções tecnológicas que não apenas entregam resultados rapidamente, mas também garantem uma base sólida de confiança em cada interação. Conte conosco para impulsionar seu sucesso com uma abordagem ágil e confiável."
                imageUrl="https://images.pexels.com/photos/3183183/pexels-photo-3183183.jpeg?auto=compress&cs=tinysrgb&w=1260"
                imageOnRight={false}
                button={secondBtn} />
        </>


    );
};

export default Home;
